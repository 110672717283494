<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" class="demo-form-inline" size="medium">
        <el-form-item prop="priceType">
          <el-select v-model="query.priceType" clearable  placeholder="请选择优惠券类型" style="width: 200px">
            <el-option label="全免" value="0"></el-option>
            <el-option label="面额券" value="1"></el-option>
            <!-- <el-option label="免费时长券" value="1"></el-option>
              <el-option label="免费停车券" value="2"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item prop="undertakerId">
          <el-select v-model="query.undertakerId" clearable placeholder="请选择承担方" style="width: 200px">
            <el-option v-for="item, index in underList" :key="index" :label="item.userName" :value="item.userId">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
            <el-select v-model="query.region" placeholder="请选择-是否转赠" style="width: 260px">
              <el-option label="是" value="0"></el-option>
              <el-option label="否" value="1"></el-option>
            </el-select>
          </el-form-item> -->
        <el-form-item prop="isUse">
          <el-select v-model="query.isUse" placeholder="请选择状态" clearable style="width: 200px">
            <el-option label="未使用" value="0"></el-option>
            <el-option label="已使用" value="1"></el-option>
            <!-- <el-option label="已过期" value="3"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item prop="couponId">
          <el-input v-model="query.couponId" placeholder="优惠券编号" style="width: 200px"></el-input>
        </el-form-item>

        <el-form-item prop="parkingLotId">
          <!-- <el-input
              v-model="formInline.user"
              placeholder="请输入使用车场名称"
              style="width: 260px"
            ></el-input> -->
          <SuggestParking v-model="query.parkingLotId" style="width: 200px" />
        </el-form-item>
        <!-- <el-form-item prop="parkingOrderId">
            <el-input
                v-model="query.parkingOrderId"
                placeholder="订单编号"
                style="width: 200px"
            ></el-input>
          </el-form-item> -->
        <el-form-item prop="phone">
          <el-input v-model="query.phone" placeholder="请输入领取手机号" style="width: 200px"></el-input>
        </el-form-item>
        <!-- <el-form-item prop="bindVehicleId">
            <NumplateSuggest
                v-model="query.bindVehicleId"
                placeholder="请输入领取车牌号"
                style="width: 200px"
            />
          </el-form-item> -->
        <el-form-item prop="plateNumber">
          <el-input v-model="query.plateNumber" placeholder="请输入使用车辆" style="width: 200px" />
        </el-form-item>
        <el-form-item>
          <div class="xxd-input__wrap"  style="background-color: #FAFAFA ;">
            领取时间：
            <el-date-picker v-model="query.receiveValue" end-placeholder="结束时间" prefix-icon="none" range-separator="~"
              start-placeholder="开始时间" type="datetimerange" unlink-panels value-format="yyyy-MM-dd HH:mm:ss"
              @change="changeDate">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="xxd-input__wrap" style="background-color: #FAFAFA ;" >
            使用时间：
            <el-date-picker v-model="query.useValue" end-placeholder="结束时间" prefix-icon="none" range-separator="~"
              start-placeholder="开始时间" type="datetimerange" unlink-panels value-format="yyyy-MM-dd HH:mm:ss"
              @change="changeDate2">
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="goSearch">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">

      <div>
        <el-table v-loading="isLoading" :data="useDataList" border class="common-table" size="medium"
          style="width: 100%" height="60vh">
          <el-table-column label="承担方名称" fixed="left" prop="undertaker" width="130">
          </el-table-column>
          <el-table-column label="优惠券类型" prop="priceType" width="100">
            <template slot-scope="scope">
              <span>{{
                priceTypeList[scope.row.priceType]
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="优惠额度(元)" prop="price" width="100">
            <template slot-scope="scope">
              <span>{{ parseFloat(scope.row.price).toFixed(2) }}</span>
            </template>
          </el-table-column>
          
          <el-table-column label="使用车辆" fixed="left" prop="vehicle" width="140">
            <!-- <template slot-scope="scope">
                <span v-if="scope.row.status === 2">{{scope.row.vehicle}}</span>
              </template> -->
            <template slot-scope="scope">
              <span v-if="scope.row.plateColor">
                <Numplate :type="scope.row.plateColor">{{
                  scope.row.plateNumber
                }}</Numplate>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="优惠券状态" fixed="left" prop="isUse" width="100">
            <template slot-scope="scope">
              <span>{{
                scope.row.isUse == 0
                  ? "未使用"
                  : "已使用"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="领取账户" prop="userId" width="180">
          </el-table-column>
          <el-table-column label="抵扣额" prop="discountAmount" width="100">
          </el-table-column>
          <el-table-column label="领取时间" prop="getTime" width="150">
          </el-table-column>
          <el-table-column label="使用时间" prop="useTime" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.isUse != 0">{{ scope.row.useTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="使用车场" prop="parkingLotName" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.isUse != 0">{{ scope.row.parkingLotName }}</span>
            </template>
          </el-table-column>
          
          <el-table-column label="订单编号" prop="orderId" width="180">
          </el-table-column>
          <!-- <el-table-column prop="name" label="第三方业务编号" width="180">
            </el-table-column> -->
          
          <el-table-column align="center" fixed="right" label="操作" prop="name" width="100px">
            <template slot-scope="scope">
              <!-- v-if="hasRights('001008003002')" -->
              <el-button size="small" style="font-size: 14px" type="text" @click="goDetail(scope.row.id)">详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination :current-page="pagination.pageNo" :page-size="pagination.pageSize"
            :page-sizes="[10, 15, 30, 50]" :total="pagination.totalSize" background
            layout="sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog :append-to-body="true" :visible.sync="exportDialogFormVisible" class="common-dialog" title="导出选项"
      width="600px">
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input v-model="form.exportSize" placeholder="请填写导出条数"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SuggestParking from "@/components/SuggestParking";
// import Bearer from "@/components/Bearer";
import { getUseCouponList } from "@/api/coupon";
import Numplate from "@/components/Numplate";
// import NumplateSuggest from "@/components/NumplateSuggest";
import { doExport, getMerchantList2 } from "@/api/common";
import Permission from "@/mixins/permission";

export default {
  name: "use-manage",
  mixins: [Permission],
  data() {
    return {
      receiveValue: [],
      useValue: [],
      underList: [],
      total: 0,
      query: {
        region: "", //是否转赠
        title: "",
        priceType: "",
        undertakerId: "",
        isUse: "",
        couponId: "",
        parkingLotId: "",
        phone: "",
        plateNumber: "",
        plateColor: "",
        startGetTime: "",
        endGetTime: "",
        startUseTime: "",
        endUseTime: "",
        receiveValue: ["", ""],
        useValue: ["", ""],
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      priceTypeList:{
        "0":"全免",
        "1":"面值",
        "2":"折扣",
        "3":"未知类型",
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      pagination: {
        pageNo: 1,
        pageSize: 30,
        totalSize: 0,
        totalPages: 1,
      },
      isLoading: false,
      useDataList: [],
    };
  },
  components: {
    SuggestParking,
    // Bearer,
    Numplate,
    // NumplateSuggest,
  },
  methods: {
    //查看使用优惠券详情
    goDetail(e) {
      this.$router.push({
        path: "/business_manage/use_detail",
        query: {
          id: e,
        },
      });
    },
    //重置查询条件
    resetForm() {
      this.$refs.query.resetFields();
      this.query.receiveValue = ["", ""];
      this.query.useValue = ["", ""];
      this.query.startGetTime = "";
      this.query.endGetTime = "";
      this.query.startUseTime = "";
      this.query.endUseTime = "";
      // this.query.useBegin = "";
      // this.query.useEnd = "";
      this.goSearch();
    },
    changeDate(e) {
      this.query.startGetTime = e[0];
      this.query.endGetTime = e[1];
    },
    changeDate2(e) {
      this.query.startUseTime = e[0];
      this.query.endUseTime = e[1];
    },
    paramFormat(query) {
      const { pageNo, pageSize } = this.pagination;

      return Object.assign(query, {
        pageNo,
        pageSize,
      });
    },
    //查询列表
    goSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 30;
      this.search();
    },
    search() {
      // this.isLoading = true;
      const params = this.paramFormat(this.query);
      if (this.receiveValue.length !== 0) {
        params["receiveBegin"] = this.receiveValue[0] + " 00:00:00";
        params["receiveEnd"] = this.receiveValue[1] + " 23:59:59";
      }
      if (this.useValue.length !== 0) {
        params["useBegin"] = this.useValue[0] + " 00:00:00";
        params["useEnd"] = this.useValue[1] + " 23:59:59";
      }
      getUseCouponList(params).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.useDataList = res.returnObject.records;
          this.pagination.totalSize = res.returnObject.total;
          this.pagination.totalPages = res.returnObject.pages;
          this.total = res.returnObject.total;
          this.form.exportSize = res.returnObject.total;
        }
      });
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.receiveValue.length !== 0) {
        params["receiveBegin"] = this.receiveValue[0] + " 00:00:00";
        params["receiveEnd"] = this.receiveValue[1] + " 23:59:59";
      }
      if (this.useValue.length !== 0) {
        params["useBegin"] = this.useValue[0] + " 00:00:00";
        params["useEnd"] = this.useValue[1] + " 23:59:59";
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport(
        "/couponController/getCouponUseList",
        params,
        "优惠券使用管理.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    handleSizeChange(e) {
      this.pagination.pageSize = e;
      this.search();
    },
    handleCurrentChange(e) {
      this.pagination.pageNo = e;
      this.search();
    },
  },
  async mounted() {
    const res = await getMerchantList2();
    if (res && res.code == 30) {
      this.underList = res.returnObject;
    }
    this.goSearch();
  },
};
</script>

<style lang="less" scoped></style>